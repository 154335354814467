:root {
  --main-bg-color: #e3e3e3;
  --primary-color: #09A8B3;
  --secondary-color: #FF009D;
}

.btn {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: black;
  color: white;
  padding: 10px 30px;
  transition: background-color 0.275s ease-out;
}

.btn:hover {
  background-color: #000000cc;
}

.btn--text {
  background-color: transparent;
  color: black;
}
.btn--text:hover {
  background-color: transparent;
  color: black;
  text-decoration: underline;
}

.btn:disabled {
  background-color: grey;
  cursor: not-allowed;
}
.btn:disabled:hover {
  background-color: grey;
}

.page {
  width: 100%;
  align-self: flex-start;
  /* margin-top: -45px; */
}

.layout {
  background-color: var(--main-bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
}
.layouts {
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.modal {
  position: fixed;
  width: 82%;
  height: 89vh;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.stock-info {
  /* max-width: 600px; */
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stock-info__form {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust the gap as needed */
}

.input-field-group {
  display: flex;
  flex-direction: column; /* Change to column to fit within the modal */
  gap: 20px; /* Adjust the gap as needed */
}

.input-field {
  display: flex;
  flex-direction: column; /* Change to column for smaller screens */
  gap: 5px; /* Space between label and input */
}


@media (min-width: 300px) {
  .input-field-group {
      flex-direction: row; /* Change to row for larger screens */
      justify-content: space-between;
  }

  .input-field {
      flex-direction: row; /* Change to row for larger screens */
      align-items: center;
  }

  .input-field label {
      flex-shrink: 0; /* Prevent the label from shrinking */
  }

  .input-field input {
      flex: 1; /* Allow the input to take up remaining space */
  }
}
.centered-label {
  display: block; /* Ensures the label takes full width */
  text-align: center;
  font-family: 'Arial', sans-serif; /* Change to your desired font */
  font-size: 1.5em;
  color: #10439F; /* Change to your desired color */
  margin-top: auto;
  font-weight: bold;
  margin-bottom: 20px;
}


.stock-info__title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #10439F;
  margin-top: auto;
}

.stocks-info__title {
  text-align: center;
  font-size: 1.5em;
  margin-bottom: auto;
  color: #10439F;
  margin-top: auto;
}

.stock-info__hr {
  display: none; /* Hide the horizontal rule */
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.input-date {
  width: 48.5%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
.input-field label {
  flex: 1; /* Allow label to grow */
  margin-right: 10px; /* Add space between label and input */
  font-weight: bold;
  font-family: system-ui;
}

.input-date label {
  flex: 1; /* Allow label to grow */
  margin-right: 10px; /* Add space between label and input */
  font-weight: bold;
  font-family: system-ui;
  padding-right: 50px;
}
.input-field input {
  flex: 2; /* Allow input to grow */
  padding: 10px;
  border: none; /* Remove the border */
  border-radius: 4px;
  background-color: #f0f0f0; /* Add a background color */
  transition: background-color 0.3s ease;
}

.input-date input {
  flex: 2; /* Allow input to grow */
  padding: 10px;
  border: none; /* Remove the border */
  border-radius: 4px;
  background-color: #f0f0f0; /* Add a background color */
  width: 200px;
}
.input-field input:focus {
  background-color: #e6f0f8; /* Change background color on focus */
}

.stock-info__form__btns {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn {
  padding: 10px 20px;
  background-color: #10439F;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.edit-btn {
  padding: 10px 20px;
  background-color: #10439F;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}


.btn--text {
  background-color: transparent;
  color: #007bff;
  border: 1px solid #007bff;
}

.btn:hover {
  background-color: #0056b3;
}

.btn--text:hover {
  background-color: gray;
  color: white;
}

.input-container {
  position: relative;
  display: inline-block;
}

.input-container input {
  padding-right: 30px; /* Adjust based on the width of the inches label */
}

.inches-label {
  position: absolute;
  right: 10px; /* Adjust to place the label correctly */
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px; /* Adjust font size as needed */
  color: #666; /* Adjust color as needed */
  pointer-events: none; /* Ensure the label doesn't interfere with input focus */
}


.header-container {
  text-align: center;
}

.header-title {
  font-size: 32px;
  font-weight: bold;
  color: #10439F;
}


/* LOGIN PAGE */

.login {
  background-color: white;
  text-align: center;
  padding: 50px 50px;
}

.login__form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 40px;
}

.login__form__error {
  font-size: 0.8em;
  color: var(--secondary-color);
  text-align: left;
  margin-top: 10px;
}

.unicast {
  max-width: 150px;

}

/* .login__form input {
  padding: 0.7em;
} */

.login__form__btn {
  font-size: medium;
  padding: 0.7em;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  margin: 10px 0;
  cursor: pointer;
  transition: background-color 0.275s ease-out;
}

.login__form__btn:hover {
  background-color: #09a8b3cc  ;
}



/* EMPLOYEE LIST PAGE */
.stock-list {
  /* margin: 50px auto; */
  max-width: 1250px;
}

.stock-list__toolbar {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.stock-list__toolbar__search {
  position: relative;
}

.stock-list__toolbar__search__icon{
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.stock-list__toolbar__search input {
  padding: 10px;
  padding-left: 40px;
}

/* Pitch Degrees */
.pitch-containers {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f0f8ff;
}

.headers {
  text-align: center;
  margin-bottom: 20px;
  color: #10439F;
}



.calculate-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color:#224976;
  color: #fff;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 20px;
  font-size: 20px;
}

.calculate-button:hover {
  background-color: #0056b3;
}

.result-container {
  margin-top: 20px;
  /* background-color: #f8f9fa; */
  background-color: #224976;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.pitch-result {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
}

.percentage-header {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

.percentage-list {
  list-style: none;
  padding: 0;
}

.result-item {
  display: flex;
  align-items: center;
  background-color: #fdfeff;
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

.percentage-label {
  flex: 1;
  font-weight: bold;
}

.percentage-value {
  margin-left: 10px;
  color: #10439F;
  font-weight: bold;
}

.check-icon {
  color: green;
  margin-right: 10px;
}


/* Propeller Thick */
.propeller-containers {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f0f8ff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  margin-bottom: 20px;
  color: #10439F;
}

.form {
  display: flex;
  align-items: center;
}

.thickness-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.thickness-button:hover {
  background-color: #0056b3;
}

.results {
  margin-top: 20px;
}

.resultText {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 20px;
  color: white;
}

.key {
  flex: 1;
  font-weight: bold;
}
.value {
  margin-left: 10px;
  color: #10439F;
  font-weight: bold;
}

.subHeader {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  color: white;
}

.list {
  list-style: none;
  padding: 0;
}

.listItem {
  display: flex;
  align-items: center;
  background-color: #fdfeff;
  margin-bottom: 8px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}

/* Check */
.wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1000px;
  margin: 0 auto;
  padding-top : 50px;
}

.propeller-container,
.pitch-container {
  flex: 1;
  margin-right: 20px;
  box-sizing: border-box;
}

.propeller-container {
  border-right: 1px solid #ccc;
  padding-right: 20px;
}

.propeller-container h2,
.pitch-container h2 {
  font-size: 30px;
  margin-bottom: 20px;
}

.form {
  margin-bottom: 20px;
}

/* Dashboard */

.dashboard {
  font-family: Arial, sans-serif;
  margin-top: 50px;
  /* padding: 20px; */
}

/* .section {
  margin-bottom: 20px;
} */

.propeller-image {
  max-width: 300px; /* Adjust the max-width as needed */
  height: auto;
}


.converter {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.converter form {
  display: flex;
  flex-direction: column;
}

.converter input {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.converter button {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

.converter button:hover {
  background-color: #0056b3;
}

h1, h2, h3 {
  color: #333;
}

/* Media query for phone orientation */
@media (max-width: 600px) {
  .dashboard {
    padding: 10px;
  }

  .custom-image-card-container {
    flex-direction: column;
    align-items: center;
  }

  .custom-image-wrapper {
    width: 100%;
    margin-bottom: 15px;
  }

  .cards {
    margin-top: 15px;
  }

  .card-container {
    display: block;
  }

  .card {
    margin-bottom: 15px;
  }

  .card-header {
    font-size: 1.2em;
  }

  .card-content {
    padding: 10px;
  }

  .horizontal-card-container {
    margin-top: 20px;
  }

  .five-columns {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
  }

  .content-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }
}

/* Card */

.custom-image-card-container {
  display: flex; /* Arrange images and card side by side */
  gap: 10px; /* Add space between the images and the card */
  align-items: flex-start; /* Align items at the start */
}

.custom-image-wrapper {
  display: flex; /* Arrange the two images side by side */
  flex-direction: column; /* Stack images vertically */
  gap: 10px; /* Space between the images */
}

.custom-propeller-image {
  max-width: 100%; /* Ensure images scale down with the container */
  height: auto; /* Maintain aspect ratio */
  border: 1px solid #ccc; /* Optional: add border for styling */
  border-radius: 5px;
  max-width: 300px; /* Optional: rounded corners */
}

.custom-aperture-image {
  max-height: auto; /* Maintain aspect ratio */
  border: 1px solid #ccc; /* Optional: add border for styling */
  border-radius: 5px;
  max-width: 300px; 
}

.card-container {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 10px;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  background-color: #fff;
}
.card-header {
  background-color: #224976;
  color: white;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.card-header1 {
  background-color: #726da8;
  color: white;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.card-header2 {
  background-color: #6495ED;
  color: white;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.card-header3 {
  background-color: #4CBB17;
  color: white;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}
.card-header4 {
  background-color: #224976;
  color: white;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.cards {
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  background-color: #fff;
  height: 295px;
}
.contents {
  border: 1px solid #ccc;
  border-radius: 20px;
  color: black;
  height: 300px;
}
.card-content p {
  font-size: 16px; /* Adjust the font size for <p> */
  color: rgb(164, 9, 9); /* Adjust the color for <p> */
  margin: 5px 0; 
  font-weight: bold;/* Optional: Adjust the margin for <p> */
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.card-contents p {
  font-size: 16px; /* Adjust the font size for <p> */
  color: #726b6b; /* Adjust the color for <p> */
  margin: 5px 0; 
  font-weight: bold;/* Optional: Adjust the margin for <p> */
  font-family: Verdana, Geneva, Tahoma, sans-serif;

}
.card-content .content-row {
  display: ruby-text;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0; /* Adjust the margin for spacing between rows */
}

.card-contents .content-row {
  display: ruby-text;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0; /* Adjust the margin for spacing between rows */
}
.card-content code {
  font-size: 14px;
  font-weight: bold;
  color: #00008b;
  /* background-color: #f9f2f4; */
  padding: 2px 4px;
  border-radius: 4px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.card-title-container {
  background-color: white;
  padding: 10px;
}


.custom-icon {
  width: 24px; /* Set the width of the icon */
  height: 24px; /* Set the height of the icon */
  /* Add any additional styles as needed */
}
.card-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: dodgerblue;
}

.card-content {
  padding: 10px;
}

.two-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
}
.five-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Create five equal-width columns */
  gap: 10px;
  list-style-type: disc; /* Use disc for bullet points */
}

.five-columns li {
  font-size: 16px; /* Adjust the font size for <li> */
  color: darkblue; /* Adjust the color for <li> */
  margin-bottom: 5px; /* Add spacing between list items */
  padding-left: 20px; /* Add padding to align bullet points */
  font-size: 14px;
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-weight: bold;
}
.horizontal-card-container {
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  overflow: hidden;
  
}


.horizontal-card {
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;

}

.horizontal-card-title {
  font-size: 18px;
  margin-right: 20px;
}

.horizontal-card-content {
  font-size: 16px;
}

.background-radial-gradient {
  background-color: hsl(218, 41%, 15%);
  background-image: radial-gradient(650px circle at 0% 0%,
      hsl(218, 41%, 35%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%),
    radial-gradient(1250px circle at 100% 100%,
      hsl(218, 41%, 45%) 15%,
      hsl(218, 41%, 30%) 35%,
      hsl(218, 41%, 20%) 75%,
      hsl(218, 41%, 19%) 80%,
      transparent 100%);
}

#radius-shape-1 {
  height: 220px;
  width: 220px;
  top: -60px;
  left: -130px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

#radius-shape-2 {
  border-radius: 38% 62% 63% 37% / 70% 33% 67% 30%;
  bottom: -60px;
  right: -110px;
  width: 300px;
  height: 300px;
  background: radial-gradient(#44006b, #ad1fff);
  overflow: hidden;
}

.bg-glass {
  background-color: hsla(0, 0%, 100%, 0.9) !important;
  backdrop-filter: saturate(200%) blur(25px);
}


/* CSS for Success Modal */
.success-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999; 
  text-align: center;
}

.success-modal p {
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
}

.success-modal button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.success-modal button:hover {
  background-color: #45a049;
}

.input[type="date"] {
  pointer-events: auto;
  opacity: 1;
}

/* Add this to your CSS file */

/* .page {
  padding: 20px;
}

.stock-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.stock-list__toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.stock-list__toolbar__search {
  display: flex;
  align-items: center;
}

.stock-list__toolbar__search__icon {
  margin-right: 10px;
}

.stock-list__grids {
  display: flex;
  gap: 20px;
}

.stock-list__table {
  flex: 1;
} */
.title {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 1.5em; /* Increase the font size for better visibility */
  /* margin: 20px 0; */
  color: #000; /* Set a dark color for the text */
}

.details-container {
  display: flex;
  justify-content: space-around; /* Evenly space items horizontally */
  align-items: center; /* Center items vertically */
  font-family: Arial, sans-serif; /* Set a clean font family */
  margin: 20px 0; /* Add vertical margin for spacing */
  flex-wrap: wrap; /* Allow wrapping to multiple lines if needed */
}

.detail {
  display: flex;
  flex-direction: column; /* Stack label and span vertically */
  align-items: center; /* Align items to the start (left) horizontally */
  justify-content: center; /* Center items vertically */
  width: 200px; /* Increased width for detail boxes */
  height: 120px; /* Increased height for detail boxes */
  margin: 10px; /* Space between boxes */
  padding: 10px; /* Padding inside each box */
  border-radius: 10px; /* Rounded corners for each box */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  color: #fff; /* White color for text */
}

.detail-dark-blue {
  background-color: #1976d2; /* Dark blue background color */
}

.detail-dark-green {
  background-color: #388e3c; /* Dark green background color */
}

.detail-dark-purple {
  background-color: #7b1fa2; /* Dark purple background color */
}

.detail-dark-orange {
  background-color: #ff9800; /* Dark orange background color */
}


.detail-dark-red {
  background-color: #d32f2f; /* Dark red background color */
}

.detail label {
  font-weight: bold; /* Make labels bold */
  margin-bottom: 5px; /* Space between label and span */
  font-size: 1.3em; /* Slightly larger font size */
}

.detail span {
  font-size: 1.1em; /* Match the font size with the label */
  display: flex;
  justify-content: center;
  width: 100%;
}


/*Dropdown UI*/
.quantity-select {
  appearance: none; /* Remove default appearance */
  padding: 8px 30px 8px 8px; /* Adjust padding as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
  position: relative; /* Ensure relative positioning for custom arrow */
}
.dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  cursor: pointer;
  background-color: white;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 170px;  /* Limit the visible options */
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: white;
  width: 100%;
  z-index: 10;
  margin-top: 5px;
  padding: 0;
  list-style-type: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.dropdowns-menu {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 140px;  /* Limit the visible options */
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: white;
  width: 100%;
  z-index: 10;
  margin-top: 5px;
  padding: 0;
  list-style-type: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.dropdown-input {
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 16px;
}
.option {
  padding: 10px;
  cursor: pointer;
}

.option:hover {
  background-color: #f0f0f0;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust position as needed */
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #555; /* Arrow color */
  pointer-events: none; /* Ensure it doesn't interfere with select interaction */
}



.titles {
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 1.5em; /* Increase the font size for better visibility */
  margin: 20px 0; /* Add vertical margin for spacing */
  color: #000; /* Set a dark color for the text */
}

.detail-container {
  display: flex;
  justify-content: space-evenly; /* Evenly space items horizontally */
  align-items: center; /* Center items vertically */
  font-family: Arial, sans-serif; /* Set a clean font family */
  margin: 20px 0; /* Add vertical margin for spacing */
}

.details {
  display: flex;
  flex-direction: column; /* Stack label and span vertically */
  align-items: center;
  justify-content: center; /* Center items vertically */
  width: 250px; /* Fixed width for detail boxes */
  height: 150px; /* Fixed height for detail boxes */
  margin: 10px; /* Space between boxes */
  padding: 20px; /* Padding inside each box */
  border-radius: 10px; /* Rounded corners for each box */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); /* Subtle shadow for depth */
  color: #fff; /* White color for text */
}

.details-dark-blue {
  background-color: #1976d2; /* Dark blue background color */
}

.details-dark-green {
  background-color: #388e3c; /* Dark green background color */
}

.details-dark-red {
  background-color: #d32f2f; /* Dark red background color */
}

.details label {
  font-weight: bold; /* Make labels bold */
  margin-bottom: 10px; /* Space between label and span */
  font-size: 1.3em; /* Slightly larger font size */
}

.details span {
  font-size: 1.1em; /* Slightly smaller font size for span */
  text-align: center; /* Center text inside the span */
}
